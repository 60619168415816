.bg-gradient-light{
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.80) 0.06%, rgba(246, 244, 242, 0.80) 71%);
}
* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-optical-sizing: none;
    text-wrap: balance;
  }

.bg-cta-image{
    background: url('/assets/img/homepage/Ballarat-Podiatry-Online-Shop.jpg'), $light;
    background-repeat: no-repeat;
    background-position:bottom;
    background-size: cover;
    @media (max-width: 992px) {
    background-image: none;
    }
}
.header-cta{
    background: url('/assets/img/About-Page-Header-Image.jpg'), $primary;
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;
    @media (max-width: 992px) {
        background-image: url('/assets/img/About-Page-Header-Image-mobile.jpg');
    }
}
.bg-stamp-blue{
    background-image: url('/assets/img/bg-stamp.svg');
    background-position: left;
    background-size: contain;
    background-repeat: no-repeat;
}

.image-badge-right{
    position: absolute;
    right: -50px;
    top: 56px;
    background-color: $white;
    border-radius: 50%;
    @media (max-width: 992px) {
        right: 50%;
        translate: 50%;
        top: unset;
        bottom: -60px;
    }
}
.grid-container {
    @media (min-width: 768px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 0px 0px;
        grid-auto-flow: row;
    }    
}
// Grid display: Box 1 on the left, box 2 and 3 on the right
.grid-box-1 {
    @media (min-width: 768px) {
        grid-area: 1 / 1 / 3 / 2;
    }
}
.grid-box-2 {
    @media (min-width: 768px) {
        grid-area: 1 / 2 / 2 / 3;
    }
}
.grid-box-3 {
    @media (min-width: 768px) {
        grid-area: 2 / 2 / 3 / 3;
    }
}
// Grid display: Box 4 on the right, box 5 and 6 on the left
.grid-box-4 {
    @media (min-width: 768px) {
        grid-area: 1 / 2 / 3 / 3;
        padding-left: 15%;
    }
}
.grid-box-5 {
    @media (min-width: 768px) {
        grid-area: 1 / 1 / 2 / 2;
    }
}
.grid-box-6 {
    @media (min-width: 768px) {
        grid-area: 2 / 1 / 3 / 2;
    }
}

// Grid display for Wart page
@media (min-width: 768px) {
    .wart-grid-container {  display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
        gap: 0px 0px;
        grid-auto-flow: row;
    }

    .wart-grid-box-1 { grid-area: 2 / 1 / 4 / 7; }

    .wart-grid-box-2 { grid-area: 1 / 1 / 2 / 13; margin-top: 10%;}

    .wart-grid-box-3 { grid-area: 2 / 8 / 3 / 13; }

    .wart-grid-box-4 { grid-area: 4 / 6 / 6 / 13; }

    .wart-grid-box-5 { grid-area: 3 / 8 / 4 / 13; }

    .wart-grid-box-6 { grid-area: 4 / 1 / 6 / 6; }
} 

.dropdown-menu{
    &.mega[data-bs-popper]{
        left: -100px;
        @media (max-width: 992px) {
            left: 0;
        }
    }
    &.mid{
        min-width: 460px;
        @media (max-width: 992px) {
            min-width: 100%;
        }

    }
    &.mega{
        min-width: 800px;
        @media (max-width: 992px) {
            min-width: 100%;
        }
    }
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
    border: 0px solid transparent;
    border-bottom: 2px solid #80C342;
}


.bg-none{
    background: transparent;
}
 
@media (min-width:992px) {
    .bg-lg-light{
        background: $light;
    }
}

//services
.service-card{
    overflow: hidden;
    img{
        transition: all .4s;
        transform: scale(1);
    }

    .card-body{
        z-index: 2;
    }
    &:hover{
        .light-link{
            color: #80C342;
            text-decoration-color: #80C342;
            fill: #80C342;
            stroke: #80C342;
        }

        img{
            transform: scale(1.03);
        }
    }
}

//footer table responsive
.table-flex-col-sm{
    @media(max-width: 992px){
        text-align: center;
        tr{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 12px;
        }
        td{
            padding: 0;
        }

    }
}

// Form
.g-recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
}

//Details page
.wysiwyg-content{
    p,h1,h2,h3,h4,h5,h6{
        margin-bottom: 2rem;
    }

    img{
        border-radius: var(--bs-border-radius);
    }

    a{
        text-decoration: underline;
    }

    hr{
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;
    }
}


//Blog tag filter cursor
.blog-filter{
    cursor: pointer;
}
