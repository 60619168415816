.animate {

  //initial values
  &.fade-up {
    transform: translate3d(0, 100px, 0);
    opacity: 0;
  }

  &.fade-bottom {
    transform: translateY(50px);
    opacity: 0;
  }

  &.fade-left,
  &.fade-left-overlay {
    transform: translate3d(-50%, 0, 0);
    opacity: 0;
  }

  &.fade-right {
    transform: translate3d(50%, 0, 0);
    opacity: 0;
  }

  &.fade-in {
    opacity: 0;
  }

  &.slide-in-down {
    transform: translate3d(0, -50%, 0);
    opacity: 0;
  }
  &.slide-in-up {
    transform: translate3d(0,50%,0);
    opacity: 0;
  }


  &.active {
    -webkit-animation-duration: 800ms;
    animation-duration: 800ms;
    -webkit-animation-delay: .1s;
    animation-delay: .1s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;

    &.fade-up {
      animation-name: fade-up;
    }

    &.fade-bottom {
      animation-name: fade-bottom;
    }

    &.fade-left {
      animation-name: fade-left;
    }

    &.fade-left-overlay {
      animation-name: fade-left-overlay;
    }

    &.fade-right {
      animation-name: fade-right;
    }

    &.fade-in {
      animation-name: fade-in;
    }

    &.slide-in-down {
      animation-name: slide-in-down;
    }
    &.slide-in-up {
      animation-name: slide-in-up;
    }

    &.delay-animate-100 {
      animation-delay: 100ms;
    }

    &.delay-animate-200 {
      animation-delay: 200ms;
    }

    &.delay-animate-300 {
      animation-delay: 300ms;
    }

    &.delay-animate-400 {
      animation-delay: 400ms;
    }
    &.delay-animate-500 {
      animation-delay: 500ms;
    }
  }

}

.pulse {
  animation: pulse-animation 1.5s infinite;
}

.pi-spin {
  -webkit-animation: pi-spin 2s linear infinite;
  animation: pi-spin 2s linear infinite;
  width: 32px;
  margin-top: 50px;
}


@keyframes pi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn)
  }
}

@keyframes fade-up {
  0% {
    transform: translate3d(0, 100px, 0);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-left {
  0% {
    transform: translate3d(-10%, 0, 0);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-left-overlay {
  0% {
    transform: translate3d(-10%, 0, 0);
    opacity: 0;
  }

  100% {
    transform: translate(12.6%, 0%);
    opacity: 1;
  }
}

@keyframes fade-right {
  0% {
    transform: translate3d(10%, 0, 0);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-down {
  0% {
    -webkit-transform: translate3d(0, -20%, 0);
    transform: translate3d(0, -20%, 0);
    opacity: 0;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes slide-in-up {
  0% {
      -webkit-transform: translate3d(0,20%,0);
      transform: translate3d(0,20%,0);
      opacity: 0
  }

  to {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
  }
}




@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba($secondary, 0.3);
  }

  100% {
    box-shadow: 0 0 0 20px rgba($secondary, 0);
  }
}

.stand-alone-animation{
  &.fade-up {
    animation-name: fade-up;
  }

  &.fade-bottom {
    animation-name: fade-bottom;
  }

  &.fade-left {
    animation-name: fade-left;
  }

  &.fade-left-overlay {
    animation-name: fade-left-overlay;
  }

  &.fade-right {
    animation-name: fade-right;
  }

  &.fade-in {
    animation-name: fade-in;
  }

  &.slide-in-down {
    animation-name: slide-in-down;
    transition: slide-in-down 0.15s linear;
  }
  &.slide-in-up {
    animation-name: slide-in-up;
    transition: slide-in-up 0.15s linear;
  }
}