.dropdown-toggle {
    &::after {
        content: '';
        width: 0.5em;
        height: 0.5em;
        border-right: 0.12em solid white;
        border-top: 0.12em solid white;
        border-left: 0;
        transform: rotate(136deg);

        @media (max-width:991px) {
            top: .775em;
        }
    }
    &.active{
        &::after{
            border-color: $secondary;
        }
    }
}

.navbar-nav {
    --bs-nav-link-font-size: 0.9375rem;

}

.navbar {
    background-color: $primary;
    .navbar-brand{
        width:163px;
    }
    .nav-link {
        line-height: 1.125rem;
        border-bottom: 3px solid transparent;

        @media (max-width:991px){
            --bs-nav-link-font-size:1.5rem;
            font-weight: 400;
            line-height: 1.875rem;
            --bs-nav-link-padding-y:0.75rem;
        }

        @media (min-width:992px) {
            &.active {
                border-color: $secondary;
            }
        }
    }

    @media (min-width:992px) {
        &.scrolled {
            background-color: $white;
            color: $primary;
            --bs-navbar-color: $primary;
            box-shadow: 0 10px 45px 0 rgba(0, 0, 0, 0.06);

            .navbar-brand {
                path[fill='white'] {
                    fill: $primary;
                }
            }

            .btn-outline-secondary {
                --bs-btn-color: $primary;
            }

            .dropdown-toggle{
                &::after {
                    border-color: $primary;
                }
                &.active{
                    &::after{
                        border-color: $secondary;
                    }
                }
            }
        }
    }
}


@media (max-width:991px){
    .navbar-brand{
        max-width: 125px;
    }
}







// .fixed-top {
// 	&.scrolled {
// 		background-color: $primary;
// 	}
// }

// .navbar-toggler {
// 	color: $light;
// 	border: none;

// 	&:focus {
// 		box-shadow: none;
// 	}
// }

// .navbar-nav,
// .nav {
// 	&.nav-dark-text {
// 		.nav-link {
// 			color: $primary;
// 		}
// 	}
// 	.nav-link {
// 		color: $light;

// 		transition: all .3s ease-in-out;
// 		text-transform: uppercase;

// 		&.active,
// 		&.show,
// 		&:hover {
// 			color: $secondary;
// 		}
// 	}





// }

// .navbar-nav {
// 	.nav-link {
// 		font-size: 2rem;
// 	}
// }



// .menu-nav {
// 	font-weight: 400;
// 	font-size: 18px;
// 	line-height: 50%;
// 	margin-right: 15px;
// 	position: absolute;
// 	right: 42px;
// 	top: 50%;
// 	color: $light;

// 	@media (max-width: 768px) {
// 		display: none;
// 	}
// }

// .outer-menu {
// 	position: relative;
// }

// .outer-menu .checkbox-toggle {
// 	position: relative;
// 	top: 0;
// 	right: 0px;
// 	z-index: 2;
// 	cursor: pointer;
// 	width: 60px;
// 	height: 60px;
// 	opacity: 0;
// }

// .outer-menu .checkbox-toggle:checked+.hamburger>div {
// 	transform: rotate(135deg);
// }

// .outer-menu .checkbox-toggle:checked+.hamburger>div:before,
// .outer-menu .checkbox-toggle:checked+.hamburger>div:after {
// 	top: 0;
// 	transform: rotate(90deg);
// }

// .outer-menu .checkbox-toggle:checked+.hamburger>div:after {
// 	opacity: 0;
// }

// .outer-menu .checkbox-toggle:checked~.menu {
// 	pointer-events: auto;
// 	visibility: visible;
// }

// .outer-menu .checkbox-toggle:checked~.menu>div {
// 	transform: scale(1.5);
// 	transition-duration: 0.75s;
// 	@media (max-width:768px) {
// 		transform: scale(1.2);
//     }
// }

// .outer-menu .checkbox-toggle:checked~.menu>div>div {
// 	opacity: 1;
// 	transition: opacity 0.4s ease 0.4s;
// }

// .outer-menu .checkbox-toggle:checked:hover+.hamburger>div {
// 	transform: rotate(225deg);
// }

// .outer-menu .hamburger {
// 	position: absolute;
// 	top: 50%;
// 	right: 0;
// 	z-index: 1;
// 	width: 26px;
// 	background: transparent;
// 	border-radius: 0 0.12em 0.12em 0;
// 	cursor: pointer;
// 	transition: box-shadow 0.4s ease;
// 	-webkit-backface-visibility: hidden;
// 	backface-visibility: hidden;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .outer-menu .hamburger>div {
// 	position: relative;
// 	flex: none;
// 	width: 100%;
// 	height: 2px;
// 	background: $light;
// 	transition: all 0.4s ease;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .outer-menu .hamburger:hover .hamburger-icon {
// 	background: $light;
// }

// .outer-menu .hamburger>div.hamburger-icon.add-white,
// .outer-menu .hamburger>div.hamburger-icon.add-white:before,
// .outer-menu .hamburger>div.hamburger-icon.add-white:after {
// 	background: $light;
// }

// .outer-menu .hamburger>div:before,
// .outer-menu .hamburger>div:after {
// 	content: "";
// 	position: absolute;
// 	z-index: 1;
// 	top: -7px;
// 	left: 0;
// 	width: 100%;
// 	height: 2px;
// 	background: inherit;
// 	transition: all 0.4s ease;
// }

// .outer-menu .hamburger>div:after {
// 	top: 7px;
// }

// .outer-menu .menu {
// 	position: fixed;
// 	top: 0;
// 	left: 0;
// 	width: 100%;
// 	height: 100%;
// 	pointer-events: none;
// 	visibility: hidden;
// 	overflow: hidden;
// 	-webkit-backface-visibility: hidden;
// 	backface-visibility: hidden;
// 	outline: 1px solid transparent;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .outer-menu .menu>div {
// 	width: 200vw;
// 	height: 200vw;
// 	color: $light;
// 	background: $primary;
// 	border-radius: 50%;
// 	transition: all 0.4s ease;
// 	flex: none;
// 	transform: scale(0);
// 	-webkit-backface-visibility: hidden;
// 	backface-visibility: hidden;
// 	overflow: hidden;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .outer-menu .menu>div>div {
// 	text-align: center;
// 	max-width: 90vw;
// 	max-height: 100vh;
// 	opacity: 0;
// 	transition: opacity 0.4s ease;
// 	overflow-y: auto;
// 	flex: none;
// 	display: flex;
// 	flex-direction: column;
// 	align-items: center;
// 	justify-content: center;
// }

// .outer-menu .menu>div>div>ul {
// 	list-style: none;
// 	padding: 0 1em;
// 	margin: 0;
// 	display: block;
// 	max-height: 100vh;
// 	overflow: hidden;

// }

// .outer-menu .menu>div>div>ul>li {
// 	padding: 0;
// 	margin: .5em;
// 	font-size: 30px;
// 	font-weight: 300;
// 	display: block;
// 	color: $white;
// }

// .outer-menu .menu>div>div>ul>li>a {
// 	position: relative;
// 	display: inline;
// 	cursor: pointer;
// 	transition: color 0.4s ease;
// }

// .outer-menu .menu>div>div>ul>li>a:hover:after {
// 	width: 100%;
// }