

.letter-spacing-lg{
    letter-spacing: 0.1125rem;
}

.letter-spacing-sm{
    letter-spacing: 0.05rem;
}

.lh-md{
    line-height: $line-height-md;
}

.fs-sm{
    font-size: $font-size-sm;
}
.fw-500{
    font-weight: 500;
}
